<template>
  <div class="main-contents-wrap">
    <div class="page-search-wrap_sup">
      <div class="date-wrap" style="margin-right: 5px">
        <input type="text" class="dateSelector start flatpickr-input" placeholder="날짜를 선택하세요." value="2021-10-05T00:00:00" readonly="readonly" />
        <a class="input-button" title="toggle" data-toggle=""><i class="icon-calendar"></i></a>
        <input type="datetime-local" name="startDate" id="startDate" value="2021-10-05T00:00:00" />
        <span>~</span>
        <input type="text" class="dateSelector end flatpickr-input" placeholder="날짜를 선택하세요." value="2021-10-05T23:59:59" readonly="readonly" />
        <a class="input-button" title="toggle" data-toggle=""><i class="icon-calendar"></i></a>
        <input type="datetime-local" name="endDate" id="endDate" value="2021-10-05T23:59:59" />
      </div>
      <div class="date-selBtn-wrap">
        <input type="radio" id="date-selector1" name="date-selector" value="today" />
        <label for="date-selector1">오늘</label>
        <input type="radio" id="date-selector2" name="date-selector" value="aweek" />
        <label for="date-selector2">1주</label>
        <input type="radio" id="date-selector3" name="date-selector" value="weeks" />
        <label for="date-selector3">2주</label>
        <input type="radio" id="date-selector4" name="date-selector" value="amonth" />
        <label for="date-selector4">한달</label>
      </div>

      <input type="text" placeholder="아이디 입력" id="memId" name="memId" value="" />
      <input type="text" placeholder="닉네임 입력" id="memNick" name="memNick" value="" />
      <button class="btn-search" type="button" onclick="search();">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div class="main-contents-wrapper">
      <table class="square-table">
        <thead id="thead">
          <tr>
            <th width="30px"></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.0">{{ $t('table.head.id') }}</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.1">닉네임</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.88">적립내용</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.79">베팅금액</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.35">적립포인트</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.6">보유머니</span></th>
          </tr>
        </thead>
        <tbody class="stair-table">
          <tr class="stair-row parent-row mDepth-0 active" data-id="gktest" data-depth="0">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT0gktest" class="btn-stairOpen open" onclick="stairBtnCall(this);loadcompanymore('gktest', 1,10,this);" style="left: 0px" data-chk=" 1"><i class="fas fa-minus"></i></button>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gktest')">gktest</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gktest')">지케이</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>93,500</span></td>
            <td class="stair-data"><span>935</span></td>
            <td class="stair-data"><span>173,000</span></td>
          </tr>
          <tr class="stair-set active parent-row" data-set="gktest" id="0gktest">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>173,000</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="09tesf" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT109tesf" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('09tesf', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('09tesf')">09tesf</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('09tesf')">aaaehsi</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="09tesf" id="109tesf">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="acer123456" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1acer123456" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('acer123456', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('acer123456')">acer123456</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('acer123456')">송사장</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="acer123456" id="1acer123456">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gk2" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gk2" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('gk2', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gk2')">gk2</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gk2')">지케2</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gk2" id="1gk2">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gk3" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gk3" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('gk3', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gk3')">gk3</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gk3')">지케3</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gk3" id="1gk3">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gkgk2" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gkgk2" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('gkgk2', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gkgk2')">gkgk2</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gkgk2')">Tttt</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gkgk2" id="1gkgk2">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gktest1234" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gktest1234" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('gktest1234', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gktest1234')">gktest1234</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gktest1234')">gktest1234</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gktest1234" id="1gktest1234">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gktest7" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gktest7" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('gktest7', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gktest7')">gktest7</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gktest7')">테스터7</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gktest7" id="1gktest7">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="gktst" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1gktst" class="btn-stairOpen" onclick="stairBtnCall(this);loadcompanymore('gktst', 2,4,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('gktst')">gktst</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('gktst')">지케</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="gktst" id="1gktst">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-row child-row mDepth-1 0gktest active" data-parent="gktest" data-id="tester1234" data-depth="1">
            <td rowspan="2" class="stair-data btn-stairOpen-wrap">
              <button type="button" id="BT1tester1234" class="btn-stairOpen btn-EmptyDot" onclick="stairBtnCall(this);loadcompanymore('tester1234', 2,0,this);" style="left: 15px"><i class="fas fa-plus"></i></button>
              <div class="treeLine" style="left: 15px; bottom: 25px"></div>
            </td>
            <td rowspan="2" class="stair-data">
              <span class="id-info hidden-none" onmouseover="idInfoVisible(this)" style="margin-left: 138px"> <a href="#none" class="userDetailOpen" onclick="userDetailOpen('tester1234')">tester1234</a> </span>
            </td>
            <td rowspan="2" class="border-div-line stair-data">
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('tester1234')">tester1234</a></span>
            </td>
            <td class="stair-data"><span>본인적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
          <tr class="stair-set active parent-row 0gktest" data-set="tester1234" id="1tester1234">
            <td class="stair-data"><span>하부적립</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
            <td class="stair-data"><span>0</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import TableHead from '@/components/main/table/Head'
// import BtnTableInner from '@/components/ui/BtnTableInner'
// import SearchBar from '@/components/main/top/SearchBar.vue'
// import TableIndex from '@/components/main/table/index'


export default {
  name: 'gameList',
  components: {
    // SearchBar
    // TableIndex,
    // TableHead,
    // BtnTableInner
  },
  data: function () {
    return {
      tableName: 'Basic',

      headInfo: {
        fstColumn: true,
        dataList: ['아이디', '닉네임', '전화번호', '입금금액', '출금금액', '손익', '보유머니', '상태', '상위충판', '회원레벨', '게임레벨', '가입날짜']
      },
      innerBtnText: '베팅내역보기'
    }
  },
  methods: {
    openWindow () {
      window.open('/user/userDetail', 'userDetail', 'top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no')
    },
    hideOpen (target) {
      target = !target
      console.log(target)
    }
  }
}
</script>

<style scoped>
.main-contents-wrapper {
  padding: 8px 10px;
}
input[type="text"] {
  margin-right: 4px;
}
#memId {
  margin-right: 4px;
}
.treeLine {
  height: 55px;
}
.btn-stairOpen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(34%);
}
</style>
